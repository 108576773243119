import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import JumbotronLayout from "../components/jumbotron-layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faBalanceScaleLeft,
  faArrowRight,
  faLayerGroup,
  faUserTie,
  faGavel,
  faFunnelDollar,
  faBaby,
  faUserAltSlash,
  faUserSlash,
  faBalanceScaleRight,
} from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import ContactUsForm from "../components/contact-us-form"
import Attorneys from "../components/attorneys"
import ContactForm from "../components/contact-us-form"
import { Col, Button, Row, Container, ResponsiveEmbed } from "react-bootstrap"
import BackgroundImage from "gatsby-background-image"
import frankProfilePhoto from "../images/attorneyscover.png"
import gregProfilePhoto from "../images/greg-white2.png"
import robbProfilePhoto from "../images/robb-keller.png"
// import Typed from '../components/typed'
// import WOW from "wow.js"

// lawyers in birmingham al birmingham personal injury attorney
export default ({ data }) => (
  <JumbotronLayout>
    <Helmet title="Bankruptcy, Criminal &amp; Divorce Attorneys">
      {/* <meta name="keywords" content="Helmet application" /> */}
      <meta
        name="description"
        content="Comprehensive bankruptcy, family, criminal, and personal injury legal representation for residents of Alabama."
      />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "http://schema.org/",
          "@type": "Organization",
          url: "http://rwkattorneys.com",
          contactPoint: [
            {
              "@type": "ContactPoint",
              telephone: "+1-205-833-2589",
              contactType: "Customer Service",
            },
          ],
          address: {
            type: "PostalAddress",
            addressLocality: "Birmingham, AL",
            postalCode: "35235-1000",
            streetAddress: "315 Gadsden Hwy, Suite D",
          },
          name: "Russo, White & Keller, P.C.",
          sameAs:
            "https://www.facebook.com/Russo-White-Keller-PC-307523019383764/",
        })}
      </script>
    </Helmet>

    <BackgroundImage
      fluid={[
        `linear-gradient(120deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 50%)`,
        data.cover.childImageSharp.fluid,
      ]}
    >
      <Container>
        <Row id="slide1" className="align-items-center justify-content-between">
          {/* <Col xs="12" sm="6" className="position-absolute">
            
          </Col> */}
          <Col xs="12" sm="6">
            <h1 className="lead tag-line text-shadow-light">
              Protecting your rights since{" "}
              <span className="text-secondary">2001</span>
            </h1>
            {/* <h1 className="lead text-dark text-xl-dark text-md-dark font-weight-normal d-none d-md-block tag-line">We <span className="text-primary">don’t</span> just <br />know the law;<br className="d-none d-md-block" /> we <span className="text-primary">understand</span> it.</h1> */}
            {/* <p className="text-dark" style={{ fontSize: '1.25vw' }}>With over 75 years of combined legal experience helping residents of Alabama with Bankruptcy, Criminal Defense and Family Law matters.</p> */}
            <p>
              <Button href="/contact">Request a Lawyer</Button>
            </p>
            <p>
              or Call <a href="tel://12058332589">(205) 833-2589</a>
            </p>
          </Col>
          <Col xs="12" sm="6">
            <ResponsiveEmbed aspectRatio="16by9">
              <iframe
                title="commercial"
                className="embed-responsive-item"
                src="https://player.vimeo.com/video/274712493"
                allowFullScreen
              ></iframe>
            </ResponsiveEmbed>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <BackgroundImage
      fluid={[
        `linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)`,
        data.cover2.childImageSharp.fluid,
      ]}
    >
      <Container
        fluid="xl"
        className="shadow"
        style={{
          background:
            "linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)",
        }}
      >
        <Row id="practiceAreas" className="align-items-center pt-5">
          <Col>
            <Row>
              <Col xs="12" sm={{ span: 8, offset: 2 }} className="text-center">
                <FontAwesomeIcon
                  icon={faBalanceScaleRight}
                  className="text-primary fa-3x"
                />
                <h1 className="lead mt-1">
                  Explore Our
                  <br />{" "}
                  <span className="text-secondary font-weight-bold">
                    Practice
                  </span>{" "}
                  Area
                </h1>
                <p className="lead">
                  Comprehensive bankruptcy, family, criminal, and personal
                  injury legal representation for residents of Alabama.
                </p>
              </Col>
              <Col xs="12" sm="4">
                <div className="m-3 text-light bg-dark shadow border border-primary scaleUp">
                  <BackgroundImage
                    className="text-center py-5 px-3"
                    fluid={[
                      `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.9) 100%)`,
                      data.bankruptcy.childImageSharp.fluid,
                    ]}
                  >
                    <FontAwesomeIcon
                      icon={faFunnelDollar}
                      className="text-secondary fa-2x"
                    />
                    <h4 className="text-secondary">Bankruptcy Law</h4>
                    <p>
                      Put a stop to creditor harassment. Safeguard your family,
                      secure your assets, and reclaim your peace of mind.
                    </p>
                    <Link
                      className="stretched-link"
                      to="/practice-areas/bankruptcy"
                    >
                      Read More
                    </Link>
                    {/* Once you have filed bankruptcy, your creditors cannot go after your assets by garnishing your wages, 
repossessing your car, foreclosing on your house, or cutting off your utility services. This is called 
the “automatic stay.” At the end of the bankruptcy process, all of your debts are discharged or 
wiped out, except those debts that cannot be discharged in bankruptcy, such as child support, 
spousal support, student loans (except under extraordinary circumstances), and most tax debts. */}
                  </BackgroundImage>
                </div>
              </Col>
              <Col xs="12" sm="4">
                <div className="m-3 text-light bg-dark shadow border border-primary scaleUp">
                  <BackgroundImage
                    className="text-center py-5 px-3"
                    fluid={[
                      `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.9) 100%)`,
                      data.criminal.childImageSharp.fluid,
                    ]}
                  >
                    <FontAwesomeIcon
                      icon={faGavel}
                      className="text-secondary fa-2x"
                    />
                    <h4 className="text-secondary">Criminal Law</h4>
                    <p>
                      Don't let a criminal charge ruin your life. A criminal
                      conviction can damage your reputation, your future job
                      prospects and your right to vote.
                    </p>
                    <Link
                      className="stretched-link"
                      to="/practice-areas/criminal-defense"
                    >
                      Read More
                    </Link>
                  </BackgroundImage>
                </div>
              </Col>
              <Col xs="12" sm="4">
                <div className="m-3 text-light bg-dark shadow border border-primary scaleUp">
                  <BackgroundImage
                    className="text-center py-5 px-3"
                    fluid={[
                      `linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,.9) 100%)`,
                      data.divorce.childImageSharp.fluid,
                    ]}
                  >
                    <FontAwesomeIcon
                      icon={faUserSlash}
                      className="text-secondary fa-2x"
                    />
                    <h4 className="text-secondary">Family Law</h4>
                    <p>
                      We will guide you through a divorce without the drama,
                      without costing you a fortune, and without ruining your
                      family.
                    </p>
                    <Link
                      className="stretched-link"
                      to="/practice-areas/family-law"
                    >
                      Read More
                    </Link>
                  </BackgroundImage>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </BackgroundImage>

    <Container fluid>
      <Row
        id="scheduleAppointment"
        className="text-center align-items-center py-5 py-sm-3 py-xl-5"
      >
        <Col>
          <h5>Call now to schedule your free in-person consultation</h5>
          <Button
            variant="outline-primary"
            className="ml-3 mt-xl-3 stretched-link"
            as={Link}
            to="/contact"
          >
            Book now
          </Button>
        </Col>
      </Row>
    </Container>

    <BackgroundImage
      fluid={[
        `linear-gradient(rgba(52,58,64, 1) 25%, rgba(0,0,0, .5))`,
        data.booksCover.childImageSharp.fluid,
      ]}
    >
      <Container className="shadow">
        <Row
          id="attorneys"
          className="text-center align-items-center py-5"
          style={{
            background: `linear-gradient(rgba(52,58,64, 1) 25%, rgba(0,0,0, .9))`,
          }}
        >
          <Col xs="12">
            <FontAwesomeIcon
              className="text-primary text-shadow-light"
              icon={faBalanceScaleLeft}
              style={{ fontSize: "4rem" }}
            />
            <h1 className="text-light mt-3 lead text-shadow-dark">
              Meet Our
              <br />{" "}
              <span className="text-primary font-weight-bold">Attorneys</span>
            </h1>
            <p className="lead text-light">
              We work together to obtain the best results for you.
            </p>
          </Col>
          <Col>
            <Attorneys />
          </Col>
        </Row>
      </Container>
    </BackgroundImage>
  </JumbotronLayout>
)

export const query = graphql`
  query {
    divorce: file(relativePath: { eq: "divorce.org.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    criminal: file(
      relativePath: { eq: "adult-alone-black-and-white-dark-551588.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bankruptcy: file(relativePath: { eq: "bankruptcy.org.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    # cover: file(relativePath: { eq: "bham_cityscape.jpg" }) {
    cover: file(relativePath: { eq: "bham_cityscape.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cover2: file(relativePath: { eq: "cover2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    booksCover: file(
      relativePath: { eq: "inaki-del-olmo-NIJuEQw0RKg-unsplash.jpg" }
    ) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    frankRusso: file(relativePath: { eq: "frank-russo2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fixed(quality: 100, width: 300) {
          ...GatsbyImageSharpFixed
          #   aspectRatio
          #   originalName
          #   sizes
          #   src
          #   srcSet
          #   srcWebp
          #   tracedSVG
        }
      }
    }
    gregWhite: file(relativePath: { eq: "greg-white2.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        fluid(quality: 100) {
          aspectRatio
          originalName
          sizes
          src
          srcSet
          srcWebp
          tracedSVG
        }
      }
    }
  }
`
